/*
 *
 *  Skinny Bones Jekyll Starter
 *
 *  Designer: Michael Rose
 *  Twitter: https://twitter.com/mmistakes
 *
*/

@import "variables";

/*
   Vendor
   ========================================================================== */

@import "vendor/bourbon/bourbon";
@import "grid-settings";
@import "vendor/neat/neat";
@import "vendor/font-awesome/font-awesome";

/*
   Global
   ========================================================================== */

@import "mixins";
@import "reset";
@import "base";

/*
   Components
   ========================================================================== */

@import "helpers";
@import "buttons";
@import "badges";
@import "bullets";
@import "sliding-menu";
@import "menu-navicons"; /* animated 3 line menu icon */
@import "notices";
@import "animations";
@import "tiles";
@import "footnotes";
@import "toc"; /* table of contents */
@import "breadcrumbs";
@import "syntax";

/*
   Page specific
   ========================================================================== */

@import "layout";
